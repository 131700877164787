/**
 * @Author: 王志鹏
 * @Date: 2025/3/10 09:36
 */

<template>
  <div class="company-list">
    <vm-table
      ref="vmTable"
      :filter.sync="filter"
      url="/organization/rental-company/page">
      <el-button slot="toolbar" type="primary" size="mini" @click="$refs.editPage.open(0)">{{$l('common.add','新增')}}</el-button>
      <template slot="adSearch">
        <div class="vm-search">
          <vm-search :label="$l('company.name','企业名称')">
            <el-input v-model.trim="filter.name" clearable></el-input>
          </vm-search>
          <vm-search label="管理员姓名">
            <el-input v-model.trim="filter.adminName" clearable></el-input>
          </vm-search>
        </div>
      </template>
      <el-table-column align="center" type="index" width="50"></el-table-column>
      <el-table-column prop="name" :label="$l('company.name','企业名称')"></el-table-column>
      <el-table-column prop="adminName" label="管理员姓名" align="center"></el-table-column>
      <el-table-column prop="adminPhone" label="管理员账号" align="center"></el-table-column>
      <el-table-column prop="amount" label="金额" align="center"></el-table-column>
      <el-table-column prop="elevatorNum" label="台量" align="center"></el-table-column>
      <el-table-column prop="contractStartTime" label="开始日期" align="center"></el-table-column>
      <el-table-column prop="contractEndTime" label="结束日期" align="center"></el-table-column>
      <el-table-column label="是否启用" align="center" width="90">
        <template slot-scope="scope">
          <el-tag :type="scope.row.isEnabled?'success':'info'">{{scope.row.isEnabled?'是':'否'}}</el-tag>
        </template>
      </el-table-column>

      <el-table-column :label="$l('common.function','操作')" align="center" class-name="vm-table_operate">
        <template slot-scope="scope">
          <el-button type="primary" @click="$refs.editPage.open(scope.row.id)">{{$l('common.edit','编辑')}}</el-button>
          <el-button type="danger" @click="deleteRow(scope.row)">{{$l('common.delete','删除')}}</el-button>
        </template>
      </el-table-column>
    </vm-table>
    <edit-page ref="editPage" @save-success="getList(-1)"></edit-page>
  </div>
</template>
<script>
  import EditPage from "./Edit.vue";
  export default {
    components: {EditPage},
    data() {
      return {
        filter: {
          name: "",
          creditCode: "",
          companyId:""        },
      };
    },
    mounted() {
      this.getList(1);
    },
    methods: {
      getList(pageNum) {
        this.$refs.vmTable.getList(pageNum);
      },
      deleteRow(row) {
        this.$confirm(`确定删除${row.name}吗?`, this.$l("common.tip", "提示"), {type: "warning"}).then(() => {
          this.$http.delete(`/organization/rental-company/${row.id}`, row.id).then(() => {
            this.getList(-1);
            this.$message.success(row.companyName + this.$l("common.deleteSuccessTip", "删除成功"));
          });
        });
      },
    },
  };
</script>



<style scoped lang="scss">

</style>
